import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "../components/Link";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import Container from "@material-ui/core/Container";
import { loadRecaptcha, SITE_KEY } from "../helper";
import Footer from "../components/nav/Footer";
import Navbar from "../components/nav/Nav";
import MyTextField from "../components/fields/MyTextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { handleLogin, isLoggedIn } from "../api";
import { navigate } from "gatsby";
import Message from "../components/authenticated/Message";
import actions from "../redux/actions";
import { useDispatch } from "react-redux";
import Loading from "../components/Loading";
import { lighten } from "@material-ui/core/styles";
import signInScheme from "../helper/schemes/signInScheme";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import Dialog from "../components/activationDialog";
import { texts } from "../data/texts";

const useStyles = makeStyles((theme) => ({
  login: {
    paddingTop: "5rem",
    minHeight: "75vh",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    textAlign: "center",
    border: "none",
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: "50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "60%",
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  btn: {
    textAlign: "center",
  },
  links: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
  },
}));

export default function SignIn({ location }) {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo();
  const [email, setEmail] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const url = location?.href ? new URL(location?.href) : null;
  const redirectTo = url ? url.searchParams.get("redirect") : null;
  const urlEmail = url ? url.searchParams.get("email") : "";

  React.useEffect(() => {
    trackPageView();
  }, []);

  useEffect(() => {
    if (location?.href) {
      const url = new URL(location?.href);
      if (url.searchParams.get("verified")) {
        dispatch(
          actions.message.addMessage({
            type: "success",
            title: texts["emailVerifiedTitle"],
            description: texts["emailVerified"],
          })
        );
      }
    }
  }, []);
  const submitData = (data, formikProps) => {
    setEmail(data.email);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "thgcontact" })
        .then((token) => requestToServer(data, token, formikProps));
    });
  };

  const requestToServer = async (data, token, formikProps) => {
    setLoading(true);
    dispatch(actions.message.removeMessage());
    const successCallback = () => {
      if (typeof window !== "undefined") {
        if (redirectTo) {
          navigate(`/authenticated/${redirectTo}`);
        } else {
          navigate("/authenticated/");
        }
      }
      formikProps.resetForm();
      setLoading(false);
    };
    const callback = () => {
      formikProps.resetForm();
      setLoading(false);
    };
    const checkStatus = () => {
      formikProps.resetForm();
      setLoading(false);
      setIsOpen(true);
    };

    handleLogin(data, token, successCallback, callback, checkStatus);
  };

  useEffect(() => {
    if (dirty) {
      loadRecaptcha();
    }
  }, [dirty]);

  if (isLoggedIn()) {
    if (redirectTo) {
      navigate(`/authenticated/${redirectTo}`);
    } else {
      navigate("/authenticated/");
    }
    return null;
  }

  if (loading || !url) return <Loading />;

  return (
    <Formik
      initialValues={{
        email: urlEmail,
        password: "",
      }}
      validationSchema={signInScheme}
      onSubmit={(values, formikProps) => {
        submitData(values, formikProps);
      }}
    >
      {(props) => {
        if (!dirty && props.dirty) {
          setDirty(true);
        }
        return (
          <div className={classes.root}>
            <Navbar />
            <Dialog open={isOpen} email={email} />
            <Form className={classes.login}>
              <Container component="main" maxWidth="xs">
                <Paper variant="outlined" className={classes.paper}>
                  <CssBaseline />
                  <div>
                    <AccountCircleIcon className={classes.avatar} />
                    <Typography component="h1" variant="h5" gutterBottom>
                      Einloggen!
                    </Typography>
                    <Message />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MyTextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Adresse"
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MyTextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Passwort"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />
                      </Grid>
                    </Grid>
                    <Grid item className={classes.btn}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Anmelden
                      </Button>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Link
                          href="/forgot-password"
                          variant="body2"
                          className={classes.links}
                        >
                          Passwort vergessen?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link
                          href="/register"
                          variant="body2"
                          className={classes.links}
                        >
                          {"Noch keinen Account? Registrieren"}
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Container>
            </Form>
            <Footer />
          </div>
        );
      }}
    </Formik>
  );
}
