import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form } from "formik";
import { lighten } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { navigate } from "gatsby";
import Loading from "../components/Loading";

import pdfagb from "../media/pdf/AGB_THG_Quoten.pdf";
import Typography from "@material-ui/core/Typography";

import MyCheckboxGroup from "../components/fields/MyCheckboxGroup";
import * as Yup from "yup";

import { BASE_URL } from "../api/config";

require("../helper/schemes/globalScheme");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    textAlign: "center",
    border: "none",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.light,
    width: "100%",
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));
const FormDialog = (props) => {
  const [firstDialog, setFirstDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [email, setEmail] = useState(false);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setFirstDialog(props.open);
  }, [props.open]);
  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);
  const handleConfirmDialog = () => {
    setFirstDialog(false);
    setConfirmDialog(true);
    // setCompanyDialog(true);
  };
  const handleClose = () => {
    setFirstDialog(false);
    window.location.reload();
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(false);
    navigate("/login");
  };

  const Subscription = async (unauthorizedCallback) => {
    setLoading(true);
    const resp = await fetch(`${BASE_URL}/thg/reactive-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        site_ref: "public",
      }),
    });
    const json = await resp.json();
    const deleted_at = json.data.deleted_at;
    setLoading(false);
    if (resp.status === 409) {
      alert("error");
    } else {
      if (deleted_at === null) handleConfirmDialog();
    }
  };
  const reactivationScheme = Yup.object().shape({
    agb: Yup.bool() // use bool instead of boolean
      .oneOf([true], "Bitte stimmen Sie den AGB zu."),
  });
  if (loading) return <Loading />;
  return (
    <div>
      {/*
        <Button variant="outlined" onClick={handlePrivateDialog}>
          Open form dialog
        </Button>
  */}
      <Dialog open={firstDialog}>
        <DialogTitle
          sx={{
            fontSize: 18,
            m: "auto",
            width: "fit-content",
          }}
        >
          Konto reaktivieren
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Formik
              initialValues={{
                agb: false,
              }}
              validationSchema={reactivationScheme}
              onSubmit={async (values, formikProps) => {
                Subscription(values, formikProps);
              }}
            >
              <Form method="POST">
                <div>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: "flex",
                      m: "auto",
                      width: "fit-content",
                    }}
                  >
                    <DialogContentText>
                      Sie haben die Möglichkeit, Ihr Konto wiederherzustellen.
                      Bestätigen Sie hierzu unsere AGB und klicken Sie auf den
                      Button <i>Konto reaktivieren</i>. Im Anschluss erhalten
                      Sie von uns eine E-Mail mit der Auftragsbestätigung.
                    </DialogContentText>
                  </Box>

                  <Box
                    noValidate
                    component="form"
                    sx={{
                      width: "fit-content",
                      display: "flex",
                    }}
                  >
                    <DialogContentText>
                      <MyCheckboxGroup
                        options={[
                          {
                            label: (
                              <Box style={{ textAlign: "left" }}>
                                <Typography>
                                  Hiermit bestätige ich, dass ich die {""}
                                  <a
                                    component="a"
                                    target="_blank"
                                    download
                                    href={pdfagb}
                                    rel="noreferrer"
                                  >
                                    AGB
                                  </a>{" "}
                                  gelesen habe.
                                </Typography>
                              </Box>
                            ),
                          },
                        ]}
                        name="agb"
                      />
                    </DialogContentText>
                  </Box>

                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      sx={{
                        width: 130,
                        backgroundColor: "#144c44",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#053c27",
                          color: "#fff",
                        },
                      }}
                    >
                      Abbrechen
                    </Button>

                    <Button
                      variant="contained"
                      sx={{
                        width: 200,
                        backgroundColor: "#144c44",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#053c27",
                          color: "#fff",
                        },
                      }}
                      type="submit"
                    >
                      Konto reaktivieren
                    </Button>
                  </DialogActions>
                </div>
              </Form>
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={confirmDialog} handleClose={() => setConfirmDialog(false)}>
        <DialogTitle
          sx={{
            fontSize: 18,
            m: "auto",
            width: "fit-content",
          }}
        >
          Die Wiederherstellung war erfolgreich.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sie können sich jetzt wieder mit Ihren Zugangsdaten einloggen.
          </DialogContentText>
          <DialogActions>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                marginTop: 20,

                marginX: "auto",
                width: "fit-content",
              }}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={closeConfirmDialog}
                sx={{
                  width: 210,

                  borderColor: "#053c27",
                  color: "#053c27",
                  "&:hover": {
                    backgroundColor: "#053c27",
                    color: "#fff",
                    borderColor: "#053c27",
                  },
                }}
              >
                OK
              </Button>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Private */}
    </div>
  );
};
export default FormDialog;
